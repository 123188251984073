<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="10" :offset="3">
                    <FormItem label="类型" required>
                        <RadioGroup type="button" v-model="form.type">
                            <Radio :label="0">单图片</Radio>
                            <Radio :label="1">多图片</Radio>
                            <Radio :label="2">视频</Radio>
                            <!-- <Radio :label="3">文本</Radio> -->
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="链接" required>
                        <RadioGroup type="button" v-model="form.link">
                            <Radio :label="0">无链接</Radio>
                            <Radio :label="1">有链接</Radio>
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="KEY" prop="key">
                        <Input :maxlength="30" show-word-limit v-model="form.key" />
                    </FormItem>

                    <FormItem label="名称" prop="label" class="w50">
                        <Input :maxlength="20" show-word-limit v-model="form.label" />
                    </FormItem>

                    <FormItem label="描述">
                        <Input :maxlength="60" show-word-limit v-model="form.desc" />
                    </FormItem>

                    <FormItem label="权重">
                        <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
                    </FormItem>
                    <FormItem label="模块">
                        <Select v-model="form.module" class="w50">
                            <Option
                                :value="item.id"
                                v-for="item in modules"
                                :key="item.id"
                            >{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="10" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {
        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    key   : '',
                    value : [],
                    type  : 0,
                    link  : 0,
                    label : '',
                    desc  : '',
                    weight: 0,
                    status: 1,
                    module:0,
                },
                modules:[{id:0,name:'首页'},{id:1,name:'分享有礼'},{id:2,name:'品牌专区'},{id:3,name:'积分换好礼'},{id:4,name:'个人中心'},{id:5,name:'会员权益'}],
                validate: {
                    key: [
                        { required: true, message: '请输入KEY', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ]
                }
            }
        },
        created()
        {
            this.id && this.loadData()
        },
        methods: {
            submit()
            {
                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        const url = '/ad/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/ad/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                    })
            }
        }
    }
</script>